<!--
 * @Author: Axs <i@axs.email>
 * @Date: 2020-11-16 17:46:56
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-04-27 19:36:20
 * @Description: 
 * @FilePath: /src/views/customer-service/index.vue
-->
<template>
    <div class="page">
        <!-- <iframe :src="`${kfDomain}/api/mobileweb/home?source=肯德基&channel_id=11178&channel_key=11178h7v9&wechatapp_id=129745&key=37836nsb8&openid=${$store.state.user.userInfo.id}&nickName=${($store.state.user.userInfo.nickname||'')}&note=${$store.state.common.platformId || ''}&avatarUrl=${$store.state.user.userInfo.headImgUrl}`"
                :style="{height:ifHeight}"
                class="iframe"></iframe> -->
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        created() {
            var orderNo = this.$route.query.orderNo;
            this.$util.getCustomerService(orderNo).then(res => {
                window.location.replace(res.url + '&ZZCAppArg=SelfOpenPage')
            })
        },
        computed: {
        },
    }
</script>

<style lang="less" scoped>
    .iframe {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border: none;
        height: 100vh;
        width: 100vw;
    }
</style>
